@import '@app/scss/theme/variables';
@import '@app/scss/theme/mixin';

.page-content.home {
  .custom-search-input {
    .cities {
      position: absolute;
      z-index: 10;
      background-color: #fff;
      border: solid 1px #eee;
      border-radius: 6px;
      top: -28px;
      left: 52px;
      @include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));

      .city {
        display: block;
        text-align: left;
        padding: 8px 20px;
        border-bottom:  solid 1px #eee;
        font-weight: 500;
        color: $body-text-color;
        cursor: pointer;

        &:hover {
          background-color: #fafafa;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .type-selector {
      position: absolute;
      right: 0px;
      top: 0px;

      button {
        padding: 0px 14px;
        border-radius: 3px;
        margin-right: 3px;
        display: none;

        img {
          height: 35px;
        }

        .type-tooltip {
          width: 0px;
          position: absolute;
          padding: 0px;
          border-radius: 3px;
          font-size: 11px;
          font-weight: 300;
          background-color: #efefef;
          top: 0px;
          max-height: 100%;
          height: 100%;
          right: 65px;
          overflow: hidden;
          -webkit-transition: width 0.15 ease-in-out;
          -moz-transition: width 0.15s ease-in-out;
          -o-transition: width 0.15s ease-in-out;
          transition: width 0.15s ease-in-out;

          .title  {
            font-weight: 500;
            font-size: 14px;
            color: $color-primary;
          }
        }

        &.food {
          img {
            height: 38px;
          }
        }

        &.active {
          display: block;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          .type-tooltip {
            width: 200px;
            padding: 7px 12px;
          }

          img {
            height: 38px;
            border: solid 1px #ccc;
            border-radius: 3px;
            padding: 2px 6px;
            background-color: #fff;;
          }
        }

        @-webkit-keyframes slide {
          100% { left: 0; }
        }

        @keyframes slide {
            100% { left: 0; }
        }
      }
    }
  }

  .cities_carousel {
    .item {
      position: relative;

      figure {
        height: 280px;
        border-radius: 6px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: grayscale(1);
      }

      .city {
        padding: 20px;
        position: absolute;
        top: 25px;
        z-index: 2;
        text-align: center;
        width: 100%;

        h3 {
          color: #fff;
          font-weight: 700;
          text-align: center;
          ;
        }
      }

      .coming-soon {
        position: absolute;
        bottom: 55px;
        right: 25px;
        text-align: center;

        color: $color-1;
        font-size: 22px;
        font-weight: 800;

        //-webkit-text-stroke: 2px white;
        //-webkit-text-fill-color: $color-secondary
        //transform: rotate(-30deg);
        //text-shadow: 0px 0px 30px #ffffff;
        img {
          width: 60px;
        }
      }

      .become-partner {
        display: none;
        position: absolute;
        bottom: 60px;
        width: 100%;
        text-align: center;
        color: #fff;
        font-weight: 800;
        font-size: 20px;
      }

      &:hover {
        figure {
          filter: grayscale(0.5);

          .city {
            h3 {
              color: $color-secondary;
            }
          }
        }

        .become-partner {
          display: block;
        }

        .coming-soon {
          display: none;
        }
      }

      &.active {
        figure {
          filter: grayscale(0.7);
        }

        &:hover {
          figure {
            filter: grayscale(0.4);
          }
        }
      }
    }
  }

  .owl-carousel .owl-item {
    width: calc(20% - 20px);
    margin-right: 20px;

    .item {
      a {
        background-color: #f4f4f4;
        border: solid 1px #f0f0f0;
        border-radius: 6px;
      }
    }
  }

  .wave-separator {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 70px;
    width: 100%;

    //height: 40px;

    .separator {
      /*
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        */

      position: absolute;
      z-index: 10;
      bottom: -1px;
      left: 0px;
      width: 100%;
    }
  }

  .follow-links-wrapper {
    @include extra-small-mobile {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 1 !important;
  }

  .hero_explained {
    position: relative;
    min-height: 570px;
  }

  .owl-stage {
    &::before {
      content: "";
      display: block;
      min-height: 1px;
      float: left;
      width: calc(10% + 10px);

      @include mobile {
        display: none;
      }
    }

    .owl-item {
      @include mobile {
        width: calc(50% - 20px);
        margin-left: 10px;
        margin-right: 10px;
      }

      a {
        @include mobile {
          padding-top: 30px;
          padding-bottom: 30px;
        }

        h3 {
          @include mobile {
            font-size: 13px;
            min-height: 30px;
          }
        }
      }

      /*
        &:last-child {
          @include mobile {
            display: none;
          }
        }*/
    }
  }

  .quick {
    margin-bottom: 80px;

    .tag-image {
      display: block;
      height: 60px;
      margin: auto;
      margin-top: 60px;
      margin-bottom: 40px;
    }

    .pills {
      text-align: center;

      .pill {
        display: inline-block;
        margin: 0px 4px;
        margin-bottom: 10px;
        background: $color-primary-10;
        color: $color-primary;
        font-weight: 600;
        font-size: 16px;
        border-radius: 20px;
        cursor: pointer;

        a {
          padding: 7px 22px;
          display: block;
        }

        &:hover,
        a:hover {
          color: $color-secondary;
        }
      }
    }
  }

  /*-------- Hero fullscren --------*/
  .hero_single {
    position: relative;
    height: 380px;
    @include background-size(cover);
    min-height: 380px;

    &.fullscreen {
      width: 100%;
      height: 100vh;

      &.video_bg {
        video {
          position: absolute;
          top: 0;
          left: 0;
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          opacity: 1;
          z-index: -1;
        }
      }
    }

    .cta {
      background-color: $color-secondary;
    }

    .location-icon {
      right: auto;
      left: 16px;
      font-size: 25px;
    }

    .pac-target-input {
      padding-left: 52px;

      &::placeholder {
        color: #cacaca;
      }
    }

    .cover {
      object-position: left;

      .mobile {
        img {
          min-width: 100%;
        }
      }
    }

    .around-me-container {
      padding-top: 14px;
      padding-bottom: 4px;
      text-align: left;
      //width: 100%;
      height: 100%;
      margin-left: -12px;

      button {
        height: 100%;
        max-width: 200px;
        min-height: 50px;
        color: #fff;
        background: $color-secondary;
        border: none;
      }

      @include tablet {
        margin-left: 0px;
      }
    }

    @media (max-width: 990px) {
      .cover {
        img {
          max-width: none;
        }
      }
    }
  }

  .sodexo {
    min-height: 153px;

    .main_title {
      margin-bottom: 30px;
    }

    .description {
      font-size: 12px !important;
      // margin-top: 20px;
      margin-bottom: 30px;

      p {
        margin: 0;

        &:last-child {
          margin-top: 5px;
        }
      }
    }

    .logo {
      margin-top: -36px;
      margin-bottom: 30px;
      text-align: right;

      img {
        height: 50px;
      }
    }

    .mobile {
      display: none;
    }
  }

  @media (max-width: $small-device-width) {
    .sodexo {
      .main_title {
        margin-bottom: 0px;
      }

      .logo {
        margin-top: 35px;
      }

      .mobile {
        display: block;
        font-size: 12px;
        padding-bottom: 20px;
        max-width: 90%;
        margin: auto;
      }
    }
  }

  @media (min-width: $small-device-width) {
    .sodexo {
      .logo {
        text-align: right;

        img {
          margin-top: 35px;
        }
      }
    }
  }

  .list_home {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        position: relative;
        width: 100%;

        @include mobile {
          overflow: hidden;
        }

        a {
          border: 1px solid $border-color-2;
          @include border-radius(5px);
          padding: 15px 15px 15px 225px;
          color: $body-text-color;
          margin-bottom: 30px;
          height: 140px;

          display: block;

          @include desktop {
            padding: 15px 15px 15px 145px;
          }

          @include extra-small-mobile {
            padding: 145px 15px 15px 15px;
            min-height: inherit;
          }

          figure {
            @include border-radius(5px 0 0 5px);
            width: 200px;
            height: 138px;
            margin-bottom: 0px;
            border-right: solid 1px #eee;
            overflow: hidden;
            position: absolute;
            left: 1px;
            top: 1px;

            @include desktop {
              width: 120px;
            }

            @include extra-small-mobile {
              position: absolute;
              width: 100%;
              top: 0;
              left: 0;
              right: 0;
              @include border-radius(5px 5px 0 0);
            }

            img {
              width: 200px;
              height: auto;
              position: absolute;
              left: 50%;
              top: 50%;
              @include transform (translate(-50%, -50%) scale(1.05));
              @include transition-default;
              @include backface-visibility (hidden);

              @include extra-small-mobile {
                width: 100%;
                max-width: 100%;
                height: auto;
              }
            }
          }

          .info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 100%;

            @include mobile {
              margin-top: 10px;
            }

            .top {
              justify-content: normal;
            }

            .score {
              position: absolute;
              top: 10px;
              right: 10px;
            }

            em {
              @include fontSize(12px);
            }

            h3 {
              @include fontSize(16px);
              margin-bottom: 0;
            }

            small {
              justify-content: normal;
              padding-top: 4px;
              display: block;
              @include fontSize(14px);
              color: #555;
            }

            ul {
              margin-top: 10px;
              align-self: flex-end;

              li {
                display: inline-block;
                width: auto;
                margin: 0 5px 0 0;

                &:last-child {
                  margin: 0
                }

                .ribbon {
                  position: static;
                }
              }
            }
          }

          &:hover {
            @include box-shadow (0 2px 4px rgba(3, 27, 78, .06));

            h3 {
              color: $color-1;
              @include transition-default;
            }
          }

          @include mobile {
            height: auto;
          }
        }
      }
    }
  }

  .call_section {
    background-position: top center;
    background-repeat: no-repeat;
    @include background-size(cover);
    min-height: 420px;
    padding: 8% 0;
    position: relative;
    overflow: hidden;

    .box_1 {
      margin-top: 70px;
      background-color: $color-4;
      color: #fff;
      padding: 45px;

      p {
        opacity: 0.8;
        @include fontSize(16px);
      }

      h3 {
        color: #fff;
      }
    }

    .cover {
      margin: 0px;

      img {
        max-width: none;
      }
    }
  }

  @media (max-width: $small-device-width) {
    .hero_single {
      min-height: 580px;
      height: 580px;

      .cover {
        height: 580px;
      }

      h1 {
        max-width: 90%;
        line-height: 1.2em;
        margin: auto;
        margin-top: 70px;
        padding-bottom: 40px;
      }
    }

    .funds {
      display: none;
    }
  }

  @media (min-width: $medium-device-width) {
    .searchbar {
      scale: 0.8;
    }
  }

  @media (min-width: $large-device-width) {
    .searchbar {
      scale: 0.8;
    }
  }

  @media (min-width: $extra-large-device-width) {
    .searchbar {
      scale: 1;
    }
  }

  @media (max-width: 1400px) {
    h1 {
      font-size: 2.2em;
    }
  }

  @media (max-width: 990px) {
    .around-me-container {
      text-align: center !important;
      margin-top: -15px;
    }

    .hero_single {
      overflow: hidden;

      .cover {
        overflow: hidden;
      }
    }

    .custom-search-input {
      .type-selector {
        button {
          margin-right: 0px;

          &:hover {
            .type-tooltip {
              width: 268px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 860px) {
    .around-me-container {
      text-align: center !important;
      margin-top: -15px;
    }

    .hero_single {
      .cover {
        .default {
          display: none;
        }

        .mobile {
          display: block !important;
        }
      }
    }

    .call_section {
      .cover {
        left: -600px;
        top: -250px;
      }
    }
  }

  @media (max-width: 445px) {
    .around-me-container {
      text-align: center !important;
      margin-top: -15px;
    }

    .custom-search-input {
      .type-selector {
        button {
          margin-right: 0px;

          &:hover {
            .type-tooltip {
              width: calc(100vw - 174px);
            }
          }
        }
      }
    }
  }
}